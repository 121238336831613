// src/components/SplineScene.js
import React from 'react';
import Spline from '@splinetool/react-spline';

const SplineScene = () => {
  return (
    <Spline scene="https://prod.spline.design/0X3TQ9VQehvmcatM/scene.splinecode" />
  );
};

export default SplineScene;
